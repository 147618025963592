import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/core";
import GrayDouble from "@media/backgrounds/gray_double_triangle.svg";
import GraySingle from "@media/backgrounds/gray_single_triangle.svg";
import RedSingle from "@media/backgrounds/red_single.svg";
import BlueSingle from "@media/backgrounds/blue_single.svg";
import GrayRightRounded from "@media/backgrounds/gray_right_rounded.png";
import GraySingleReverse from "@media/backgrounds/gray_single_triangle_reverse.svg";
import BlackSingle from "@media/backgrounds/black_single.svg";

const Background = ({ type, children, tagName, className }) => {
  const backgrounds = {
    "gray-double": GrayDouble,
    "gray-single": GraySingle,
    "gray-single-reverse": GraySingleReverse,
    "red-single": RedSingle,
    "blue-single": BlueSingle,
    "gray-right-rounded": GrayRightRounded,
    "black-single": BlackSingle
  };
  const TagName = tagName;
  return (
    <TagName
      className={className}
      css={css`
        background-image: ${type ? `url(${backgrounds[type]})` : ""};
      `}
    >
      {children}
    </TagName>
  );
};

Background.defaultProps = {
  className: "",
  type: "gray-double"
};

Background.propTypes = {
  type: PropTypes.oneOf([
    "gray-double",
    "gray-single",
    "red-single",
    "blue-single",
    "gray-right-rounded",
    "black-single"
  ]),
  children: PropTypes.node.isRequired,
  tagName: PropTypes.string.isRequired,
  className: PropTypes.string
};

export default Background;
